export default {
  props: {
    data: Object,
    listKey: String,
  },
  data: () => ({
    selected: false,
    updated_at: '',
  }),
  methods: {
    toggleSelected() {
      if(this.isPopupMode) return
      if(this.selected === this.isRowSelected) return

      if(this.selected == true) {
        this.$store.dispatch(`list/${this.listKey}/select`, window.eagleLodash.cloneDeep(this.selectedData))
        return
      }

      this.$store.dispatch(`list/${this.listKey}/unselect`, window.eagleLodash.cloneDeep(this.selectedData))
    },
  },
  computed: {
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
    },
    isPopupMode() {
      return this.$store.getters[`list/${this.listKey}/isPopupMode`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    target() {
      return this.data[this.targetKey]
    },
    targetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`]
    },
    selectedData() {
      const defaultSelectedData = { [this.targetKey]: this.target }
      if(!this.listConfig) return defaultSelectedData
      if(typeof this.listConfig.selectedData === 'function') {
        return this.listConfig.selectedData(this.data)
      }
      return { [this.targetKey]: this.target }
    },
    listSelected: {
      get() {
        return this.$store.getters[`list/${this.listKey}/selected`]
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/setSelected`, value)
      },
    },
    isRowSelected() {
      return !!window.eagleLodash.find(this.listSelected, this.selectedData)
    },
  },
  watch: {
    isRowSelected: {
      immediate: true,
      handler() {
        this.selected = window.eagleLodash.cloneDeep(this.isRowSelected)
      },
    },
  },
};
