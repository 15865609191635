export default {
  components: {
    itemCard: () => import("@/modules/base/components/itemCard/itemCard.vue"),
  },
  computed: {
    statusColorClass() {
      if (
        this.data.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
      )
        return "red--text";
      return "primary--text";
    },
    actionText() {
      return this.$t(`order.status.${this.data.status}`);
    },
    cardType() {
      switch (this.data.status) {
        case "finished":
          return "lighten";
        case "reject":
        case "canceled":
          return "error";
        case "request_return":
          return "dark";
        default:
          return "secondary";
      }
    },
  },
}